import {period} from "@/helpers/period";
import gParams from "@/parametres/global";


export function groupPeriod(type="standard") {
    return {
        type: type, // standard, treasury, loan
        nbPeriod: gParams.nbPeriod,
        data: {},
        total: period(),
        checkLabel: function(label){
            if (! (label in this.data)){
                this.data[label] = period(this.type)
            }
        },
        addValue: function (label, p, val) {
            this.checkLabel(label)
            this.data[label].addValue(p, val)
            this.total.addValue(p, val)
        },
        addToEndValue: function (label, p, val) {
            this.checkLabel(label)
            this.data[label].addToEndValue(p, val)
            this.total.addToEndValue(p, val)
        },
        minusValue: function (label, p, val) {
            this.checkLabel(label)
            this.data[label].minusValue(p, val)
            this.total.minusValue(p, val)
        },
        minusToEndValue: function (label, p, val) {
            this.checkLabel(label)
            this.data[label].minusToEndValue(p, val)
            this.total.minusToEndValue(p, val)
        },
        getLoanCumulGroupDataYear: function(p) {
            let res = 0
            for(let key in this.data){
                res += this.data[key].getDataYear()[p]
            }
            return res

        },
        addLoan: function(label, vMonth, pStart, duration){
            this.checkLabel(label)
            this.data[label].addLoan(vMonth, pStart, duration)
        }
    }
}
