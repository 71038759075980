import {period} from "./period"
import {groupPeriod} from "@/helpers/groupPeriod";
import gParams from "@/parametres/global";
import cParams from "@/parametres/companies";
import hParams from "@/parametres/head";

export function Company(id, type, label, startP, group, isHead=false) {return {
    label: label,
    id:id,
    type: type, // internal or external (licencied)
    startP:startP,
    group: group,
    isHead: isHead,
    income: {
        internal: groupPeriod(),
        external: groupPeriod(),
        turnover: period()
    },
    outcome: {
        internal: groupPeriod(),
        external: groupPeriod(),
        salaries:groupPeriod(),
        debt:groupPeriod("loan"),
        expenses: period()
    },
    balance: period(),
    treasury: period("treasury"),

    addInitialTreasury: function(v){
        this.treasury.addToEndValue(0, v)
    },

    addInitialTurnover: function(to){
        let lastValue = to
        for(let i = this.startP; i < gParams.nbPeriod; i++){
            for (let key in gParams.incomeRepartition){
                const partValue = lastValue*gParams.incomeRepartition[key].rate
                this.addIn(key, i, partValue, "outward")
                if (gParams.incomeRepartition[key].totalMarginRate !== null){
                    let margin = partValue*gParams.incomeRepartition[key].marginRate
                    let groupMargin = partValue*gParams.incomeRepartition[key].totalMarginRate - margin
                    this.buyToHead(key, i, partValue-margin, groupMargin)
                }
            }
            lastValue = lastValue+lastValue*gParams.augmentationAnnuelle/12
        }
    },
    buyToHead: function(label, p, v, margin){
        if (this.type === "internal"){
            this.addOut(label, p, v, "inward")
        }
        else{
            this.addOut(label, p, v, "outward")
        }
        if (!isHead){
            this.group.buyToHead(this.type, label, p, v, margin)
        }
    },
    addIn: function (label, p, val, cat="outward"){
        //cat can be: inward, outward
        if (cat === "outward") {
            this.income.external.addValue(label, p, val)
        }
        else {
            this.income.internal.addValue(label, p, val)
        }
        this.addTurnover(p, val)
    },
    addTurnover: function(p, v){
        this.income.turnover.addValue(p, v)
        this.balance.addValue(p, v)
        this.treasury.addToEndValue(p, v)
    },
    addOut: function (label, p, val, cat="outward"){
        //cat can be: inward, outward, salaries, debt
        if (cat === "outward") {
            this.outcome.external.addValue(label, p, val)
        }
        else if (cat === "inward") {
            this.outcome.internal.addValue(label, p, val)
        }
        else {
            this.outcome.salaries.addValue(label, p, val)
        }
        this.addExpenses(p, val)
    },
    addExpenses: function(p, v){
        this.outcome.expenses.addValue(p, v)
        this.balance.minusValue(p, v)
        this.treasury.minusToEndValue(p, v)
    },
    addGeneralExpenses: function (){
        for(let i = this.startP; i < gParams.nbPeriod; i++){
            const tmpCA = this.income.turnover.getValue(i)
            let gExpenses;
            if (isHead){
                gExpenses = hParams.generalExpenses;
            }
            else {
                gExpenses = cParams.generalExpenses
            }
            const tmpExpenses = tmpCA*gExpenses
            this.addOut("Charges générales", i, tmpExpenses, "outward")
        }

    },
    addLoan: function (label, vMonth, pStart, duration){
        let endP
        if (pStart+duration < gParams.nbPeriod){
            endP = pStart+duration
        }
        else {
            endP = gParams.nbPeriod
        }
        this.outcome.debt.addLoan(label, vMonth, pStart, duration)
        for(let i = pStart; i < endP; i++){
            this.treasury.minusToEndValue(i, vMonth)
        }
    },
    addEmployee: function (label, vMonth, p){
        for(let i = p; i < gParams.nbPeriod; i++){
            this.addOut(label, i, vMonth, "salaries")
        }

    },
    processFee: function(label, p){
        const to = this.income.turnover.getValue(p)
        const fee = to*cParams.annualFee;
        this.addOut(label, p, fee, "inward")
        return fee
    }

}}
