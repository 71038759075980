<template>
  <tr>
    <td class="font-bold">{{ rowKey }}</td>
    <template v-for=" val in data" :key="rowKey + val">
      <td v-if="val">{{numeralFormat(val)}}</td>
      <td v-else>&nbsp;</td>
    </template>
  </tr>
</template>
<script>
import {computed} from "vue";
import gParams from "@/parametres/global";

export default {
  props: {
    rowData:Object,
    rowKey:String
  },
  setup(props){
    const data = computed(() => {
      if (Array.isArray(props.rowData)){
        return props.rowData.slice(0,gParams.nbPeriod);
      }
      else{
        return props.rowData.data.slice(0,gParams.nbPeriod)
      }
    })

    return {data}
  }
}



</script>
