import gParams from "@/parametres/global";


export function period(type="standard") {
    return {
        type: type, // standard, treasury, loan, valuation
        nbPeriod: gParams.nbPeriod,
        data: new Array(gParams.nbPeriod).fill(0),

        addLoan: function (vMonth, pStart, duration){
            if (pStart+duration > this.nbPeriod){
                this.nbPeriod = pStart+duration
                this.data = new Array(this.nbPeriod).fill(0)
            }
            for(let i = pStart; i < pStart+duration; i++){
                this.addValue(i,vMonth)
            }
        },

        addValue: function (p, val) {
            this.data[p] = this.data[p] + val
        },

        addToEndValue: function (p, val) {
            for(let i = p; i < this.nbPeriod; i++){
                this.data[i] = this.data[i] + val
            }

        },
        minusValue: function (p, val) {
            this.data[p] = this.data[p] - val
        },
        minusToEndValue: function (p, val) {
            for(let i = p; i < this.nbPeriod; i++){
                this.data[i] = this.data[i] - val
            }

        },
        getValue: function (p) {
            return this.data[p]
        },
        getYearFromP: function(p){
            return (p-(p % 12))/12
        },
        getNbYears: function () {
            return this.getYearFromP(this.nbPeriod)
        },
        getDataYear: function(){
            if(this.type === "standard"){
                let res = new Array(this.getNbYears()).fill(0);
                for(let p = 0; p < this.nbPeriod; p++){
                    res[this.getYearFromP(p)]+=this.data[p]
                }
                return res.slice(0,this.getYearFromP(gParams.nbPeriod))
            }
            if (this.type === "treasury"){
                let res = new Array(this.getNbYears()).fill(0);
                for(let i = 0; i < this.nbPeriod/12; i++){
                    res[i]=this.data[i*12+12-1]
                }
                return res.slice(0,this.getYearFromP(gParams.nbPeriod))
            }
            if (this.type === "loan"){
                let res = new Array(this.getNbYears()).fill(0);
                for(let i = 0; i < this.nbPeriod/12; i++){
                    for(let j = (i+1)*12; j < this.nbPeriod; j++){
                        res[i]+=this.data[j]
                    }
                }
                return res.slice(0,this.getYearFromP(gParams.nbPeriod))
            }
            return []
        }

    }
}
