<template>
  <TabView>
    <TabPanel header="Groupe">

      <h1>Groupe 8TECH</h1>
      <h2>Réseau de services informatiques B2B</h2>

      <div class="grid">
        <div class="col-6">
          <table class="bptable" style="width: 100%">
            <tr class="title"><td :colspan="nbYears + 1">Synthèse</td></tr>
            <tr class="separator"><td :colspan="nbYears + 1"> </td></tr>
            <tr class="title">
              <td>Années</td>
              <td v-for="n in nbYears" :key="n">{{n}}</td>
            </tr>
            <bp-row-year :rowData="globalData.turnover.getDataYear()" :rowKey="'CA'"/>
            <bp-row-year :rowData="globalData.balance.getDataYear()" :rowKey="'EBITDA'"/>
            <bp-row-year :rowData="globalData.resultRate" :rowKey="'EBITDA/CA'" format="percent" />
            <tr class="separator"><td :colspan="nbYears + 1"> </td></tr>
            <bp-row-year :rowData="globalData.treasury.getDataYear()" :rowKey="'Trésorerie'"/>
            <bp-row-year :rowData="globalData.debts" :rowKey="'Dettes'"/>
            <tr class="separator"><td :colspan="nbYears + 1"> </td></tr>
            <bp-row-year :rowData="globalData.profitRate" :rowKey="'Valeur des titres: EBITDAx6 + Tréso - Dettes'"/>
            <tr class="separator"><td :colspan="nbYears + 1"> </td></tr>
            <tr class="title"><td :colspan="nbYears + 1">Valorisation investisseur (basé sur 400k€ en private equity et 400k€ de concours bancaire) : 1,6M€ (1% 16k€)</td></tr>
            <bp-row-year :rowData="globalData.profitPerPercent" :rowKey="'Valeur d investissement'"/>
            <bp-row-year :rowData="globalData.profitRateInvest" :rowKey="'% investissement'" format="string"/>
          </table>
          <br>
          <Card>
            <template #title>Informations</template>
            <template #content>
              <p class="m-0">
                -
              </p>
            </template>
          </Card>

        </div>
        <div class="col-6">
          <Chart type="bar" :data="chartData3" :options="chartOptions1" class="h-30rem" />
        </div>
      </div>


      <br><br><br>
      <h2>Données de départs</h2>

      <div class="grid">
        <div class="col-4">
          <table class="bptable" style="width: 100%">
            <tr class="title"><td>Paramètres</td><td>Valeur</td></tr>
            <tr><td>Augmentation moyenne annuelle</td><td>8% du CA</td></tr>
            <tr><td>Redevance annuelle licence</td><td>2% du cA</td></tr>
            <tr class="separator"><td :colspan="nbYears + 1"> </td></tr>
            <tr class="title"><td colspan="2">CA licenciés par catégories</td></tr>
            <template v-for="(val,key) of gParams.incomeRepartition" :key="key">
              <tr>
                <td>
                  {{key}}
                </td>
                <td>
                  <table class="no-style">
                    <tr><td>Proportion du CA</td><td>:</td><td>{{ numeralFormat(val.rate * 100, '0.00') }} %</td></tr>
                    <tr><td>Marge licencié</td><td>:</td><td>{{ numeralFormat(val.marginRate * 100, '0.00') }} %</td></tr>
                    <tr v-if="val.totalMarginRate"><td>Marge 8TECH</td><td>:</td><td>{{ numeralFormat((val.totalMarginRate -val.marginRate) * 100, '0.00') }} %</td></tr>
                    <tr v-else><td>Marge 8TECH</td><td>:</td><td>0 %</td></tr>
                  </table>
                </td>
              </tr>
            </template>

          </table>
        </div>
        <div class="col-8">
          <h3>Répartition marge par produits</h3>
          <Chart type="bar" :data="chartData4" class="w-full" />
          <h3>Répartition du CA des licenciés par catégories</h3>
          <Chart type="pie" :data="chartData2" class="w-full md:w-30rem" />
        </div>
      </div>
    </TabPanel>
    <TabPanel header="8 TECH">
      <bp-company :cData="globalData.head"/>
    </TabPanel>
    <TabPanel v-for="(value) in globalData.internalCompany" :header="value.label" :key="value.id">
      <bp-company :cData="value"/>
    </TabPanel>
    <TabPanel header="Licenciés externes">
      <bp-licenced :items="globalData.externalCompany"/>
    </TabPanel>
  </TabView>
</template>

<style scoped>
  table.no-style, table.no-style tr, table.no-style td{
    border:0;
  }
</style>

<script>
import {globalGroup} from "@/helpers/group";
import BpCompany from "@/components/BpCompany.vue";
import BpLicenced from "@/components/BpLicenced.vue";

import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Chart from 'primevue/chart';
import Card from "primevue/card";
import gParams from "@/parametres/global";
import BpRowYear from "@/components/BpRowYear.vue";
import gConfig from "@/parametres/global";

export default {
  components:{BpRowYear, BpLicenced, TabView, TabPanel, BpCompany, Chart, Card},
  setup(){
    const globalData = globalGroup()

    globalData.createCompanies("lr2i", "LR2I", "internal", 0, 124375, 260000)
    globalData.createCompanies("l2", "Licencié 2", "external", 18, 83300)
    globalData.createCompanies("l3", "Licencié 3", "external", 24, 83300)
    globalData.createCompanies("l4", "Licencié 4", "external", 27, 83300)
    globalData.createCompanies("l5", "Licencié 5", "external", 30, 83300)
    globalData.createCompanies("l6", "Licencié 6", "external", 33, 83300)
    globalData.createCompanies("l7", "Licencié 7", "external", 35, 83300)
    globalData.createCompanies("l8", "Licencié 8", "external", 37, 83300)
    globalData.createCompanies("l9", "Licencié 9", "external", 39, 83300)
    globalData.createCompanies("l10", "Licencié 10", "external", 41, 83300)
    globalData.createCompanies("l11", "Licencié 11", "external", 43, 83300)
    globalData.createCompanies("l12", "Licencié 12", "external", 45, 83300)
    globalData.createCompanies("l13", "Licencié 13", "external", 47, 83300)
    globalData.createCompanies("l14", "Licencié 14", "external", 49, 83300)
    globalData.createCompanies("l15", "Licencié 15", "external", 51, 83300)
    globalData.createCompanies("l16", "Licencié 16", "external", 53, 83300)
    globalData.createCompanies("l17", "Licencié 17", "external", 55, 83300)
    globalData.createCompanies("l18", "Licencié 18", "external", 57, 83300)
    globalData.createCompanies("l19", "Licencié 18", "external", 59, 83300)

    globalData.addGeneralExpenses()

    globalData.addLoan("head","PGE",3300,0,24)
    globalData.addLoan("lr2i","PGE",1700,0,24)
    //globalData.addLoan("lr2i","LBO",6000,0,84) // 500k€
    globalData.addLoan("lr2i","LBO",4800,0,84) // 400k€
    //globalData.addLoan("lr2i","LBO",3600,0,84) // 300k€
    //globalData.addLoan("lr2i","LBO",2400,0,84) // 200k€
    //globalData.addLoan("lr2i","LBO",1200,0,84) // 100k€


    globalData.addEmployee("head","Management Fees",6750, 0)
    globalData.addEmployee("head","Gestionnaire",3319, 18)
    globalData.addEmployee("head","Gestionnaire 2",3319, 24)
    globalData.addEmployee("head","Gestionnaire 3",3319, 48)
    globalData.addEmployee("head","Dir Commercial",5000, 22)
    globalData.addEmployee("head","Resp Marketing",4000, 18)
    globalData.addEmployee("head","Admin sys",5872, 36)
    globalData.addEmployee("head","Admin sys 2",5872, 48)
    globalData.addEmployee("head","Admin sys 2",5872, 60 )
    globalData.addEmployee("head","Tech1",4000, 24)
    globalData.addEmployee("head","Tech2",4000, 28)
    globalData.addEmployee("head","Tech3",4000, 32)
    globalData.addEmployee("head","Tech4",4000, 36)
    globalData.addEmployee("head","Tech5",4000, 42)
    globalData.addEmployee("head","Tech6",4000, 46)
    globalData.addEmployee("head","Tech7",4000, 50)
    globalData.addEmployee("head","Tech8",4000, 56)



    globalData.addEmployee("lr2i","Management Fees",6750, 0)

    globalData.addEmployee("lr2i","Assist Dir",4683, 0)
    globalData.addEmployee("lr2i","Assist Admin",2660, 0)
    globalData.addEmployee("lr2i","Resp. opérationnel",4507, 0)
    globalData.addEmployee("lr2i","Admin sys 1",5872, 0)
    globalData.addEmployee("lr2i","Technicien 1",4075, 0)
    globalData.addEmployee("lr2i","Technicien 2",3319, 0)
    //globalData.addEmployee("lr2i","Dev",4210, 0)
    globalData.addEmployee("lr2i","Apprenti",1027, 0)
    globalData.addEmployee("lr2i","Secretaire Compta",312, 0)

    globalData.addEmployee("lr2i","Technicien 3",4497, 24)
    globalData.addEmployee("lr2i","Technicien 4",4497, 48)

    globalData.annualFee()

    globalData.postProcessing()




    function initLabelsYear(){
      let tmpArray= []
      for (let i = 0; i < gParams.nbPeriod/12; i++) {
        tmpArray.push(i+1);
      }
      return tmpArray
    }

    const chartData3 = {
      labels: initLabelsYear(),
      datasets: [
        {
          type: 'line',
          label: 'CA',
          data: globalData.turnover.getDataYear(),
          fill: false,
          tension: 0.4
        },
        {
          type: 'line',
          label: 'Résultat',
          data: globalData.balance.getDataYear(),
          fill: false,
          tension: 0.4
        },
        {
          type: 'bar',
          label: 'Trésorerie',
          data: globalData.treasury.getDataYear(),
          fill: false,
          tension: 0.4
        }
      ]
    }

    function getRepartitionCaData(keys){
      let tmpres=[]
      for (let k of keys){
        tmpres.push(gParams.incomeRepartition[k].rate*100)
      }
      return tmpres
    }

    const chartData2 = {
      labels: Object.keys(gParams.incomeRepartition),
      datasets: [
        {
          label: 'CA',
          data: getRepartitionCaData(Object.keys(gParams.incomeRepartition)),
          fill: false,
          tension: 0.4
        }
      ]
    }

    function getValFromKey(k,lst,dict){
      let res=[]
      for(let key of lst){
        let tmp = dict[key][k]
        if (tmp === null){tmp=1}
        res.push(tmp)
      }
      return res
    }

    const data4Keys = Object.keys(gParams.incomeRepartition)

    function getchart4serie3(){
      let tmp = getValFromKey("totalMarginRate",data4Keys,gParams.incomeRepartition).map(function (num, idx) {return num - getValFromKey("marginRate",data4Keys,gParams.incomeRepartition)[idx];})
      let res= []
      for (let x of tmp){
        if (x < 0){res.push(0)}
        else{res.push(x)}
      }
      return res
    }

    const chartData4 = {
      labels: data4Keys,
      datasets: [
        {
          label: 'Marge Total',
          data: getValFromKey("totalMarginRate",data4Keys,gParams.incomeRepartition),
          fill: false,
          tension: 0.4
        },
        {
          label: 'Marge licencié',
          data: getValFromKey("marginRate",data4Keys,gParams.incomeRepartition),
          fill: false,
          tension: 0.4
        },
        {
          label: 'Marge 8TECH',
          data: getchart4serie3(),
          fill: false,
          tension: 0.4
        }
      ]
    }

    const chartOptions1 = {
      maintainAspectRatio: false,
      aspectRatio: 0.6,
    }

    const nbYears = gConfig.nbPeriod/12

    return {globalData, chartData2, chartData3, chartOptions1, nbYears, gParams, chartData4}
  }
}



</script>
