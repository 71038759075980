<template>
  <BpGroup/>
</template>

<script>
import BpGroup from "@/components/BpGroup.vue";

export default {
  name: 'App',
  components: {
    BpGroup
  }
}
</script>
