<template>
  <h2>Résumé</h2>
  <table style="width: 100%">
    <tr>
      <td style="width: 50%">

        <Chart type="bar" :data="chartData2"  class="h-30rem"/>
      </td>
      <td style="width: 50%">



        <table class="bptable" style="width: 100%">
          <tr class="title"><td :colspan="nbYears + 1">Synthèse</td></tr>
          <tr class="separator"><td :colspan="nbYears + 1"> </td></tr>
          <tr class="title">
            <td>Années</td>
            <td v-for="n in nbYears" :key="n">{{n}}</td>
          </tr>
          <bp-row-year :rowData="cData.income.turnover.getDataYear()" :rowKey="'CA'"/>
          <bp-row-year :rowData="cData.balance.getDataYear()" :rowKey="'Résultat'"/>
          <bp-row-year :rowData="cData.treasury.getDataYear()" :rowKey="'Trésorerie'"/>
          <tr class="separator"><td :colspan="nbYears + 1"> </td></tr>
          <tr class="title"><td :colspan="nbYears + 1">Ventes</td></tr>
          <bp-row-year :rowData="cData.income.turnover.getDataYear()" :rowKey="'Total'"></bp-row-year>
          <tr class="separator"><td :colspan="nbYears + 1"> </td></tr>
          <tr class="title"><td :colspan="nbYears + 1">Charges</td></tr>
          <bp-row-year :rowData="cData.outcome.expenses.getDataYear()" :rowKey="'Total'"></bp-row-year>
        </table>




      </td>
    </tr>
  </table>


  <h2>Détails mensuels</h2>
  <table class="bptable">
    <tr class="title"><td :colspan="nbPeriod + 1">Synthèse</td></tr>
    <tr class="separator"><td :colspan="nbPeriod + 1"> </td></tr>
    <tr class="title">
      <td>Mois</td>
      <td v-for="n in nbPeriod" :key="n">{{n}}</td>
    </tr>
    <bp-row :rowData="cData.income.turnover" :rowKey="'CA'"/>
    <bp-row :rowData="cData.balance" :rowKey="'Résultat'"/>
    <bp-row :rowData="cData.treasury" :rowKey="'Trésorerie'"/>



    <tr class="separator"><td :colspan="nbPeriod + 1"> </td></tr>
    <tr class="separator"><td :colspan="nbPeriod + 1"> </td></tr>
    <tr class="separator"><td :colspan="nbPeriod + 1"> </td></tr>

    <tr class="title"><td :colspan="nbPeriod + 1">Ventes</td></tr>
    <tr class="separator"><td :colspan="nbPeriod + 1"> </td></tr>
    <tr class="title">
      <td>Mois</td>
      <td v-for="n in nbPeriod" :key="n">{{n}}</td>
    </tr>


    <bp-group-row :group-row="cData.income.external" :group-key="'Ventes Externes'"/>
    <bp-group-row :group-row="cData.income.internal" :group-key="'Ventes Internes'"/>

    <tr class="separator"><td :colspan="nbPeriod + 1"> </td></tr>
    <bp-row :rowData="cData.income.turnover" :rowKey="'Total'"></bp-row>
    <tr class="separator"><td :colspan="nbPeriod + 1"> </td></tr>
    <tr class="separator"><td :colspan="nbPeriod + 1"> </td></tr>
    <tr class="separator"><td :colspan="nbPeriod + 1"> </td></tr>

    <tr class="title"><td :colspan="nbPeriod + 1">Charges</td></tr>
    <tr class="separator"><td :colspan="nbPeriod + 1"> </td></tr>
    <tr class="title">
      <td>Mois</td>
      <td v-for="n in nbPeriod" :key="n">{{n}}</td>
    </tr>

    <bp-group-row :group-row="cData.outcome.external" :group-key="'Achats Externes'"/>
    <bp-group-row :group-row="cData.outcome.internal" :group-key="'Achats Internes'"/>
    <bp-group-row :group-row="cData.outcome.salaries" :group-key="'Salariés'"/>
    <bp-group-row :group-row="cData.outcome.debt" :group-key="'Emprunts'"/>

    <tr class="separator"><td :colspan="nbPeriod + 1"> </td></tr>

    <bp-row :rowData="cData.outcome.expenses" :rowKey="'Total'"></bp-row>



  </table>
</template>

<style>
  .bptable {
    border-collapse: collapse;
    white-space: nowrap
  }
  .bptable tr.title {
    background-color: var(--primary-color);
    color:white;
  }
  .bptable tr.separator td {
    border: 0px;
  }
  .bptable td {
    border: solid black 1px;
    padding:4px 6px;
  }

</style>

<script>
import BpRow from "@/components/BpRow.vue";
import BpRowYear from "@/components/BpRowYear.vue";
import BpGroupRow from "@/components/BpGroupRow.vue";
import gConfig from "@/parametres/global";
import Chart from 'primevue/chart';
import gParams from "@/parametres/global";

export default {
  components:{BpRow, BpRowYear, BpGroupRow, Chart},
  props:{
    cData:Object
  },
  setup(props){
    function initLabelsYear(){
      let tmpArray= []
      for (let i = 0; i < gParams.nbPeriod/12; i++) {
        tmpArray.push(i+1);
      }
      return tmpArray
    }
    const chartData2 = {
      labels: initLabelsYear(),
      datasets: [
        {
          type: 'bar',
          label: 'CA',
          data: props.cData.income.turnover.getDataYear(),
        },
        {
          type: 'bar',
          label: 'Résultat',
          data: props.cData.balance.getDataYear(),
        },
        {
          type: 'line',
          label: 'Trésorerie',
          data: props.cData.treasury.getDataYear(),
        },

      ]
    }

    const chartOptions1 = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
    }




    const nbPeriod = gConfig.nbPeriod
    const nbYears = gConfig.nbPeriod/12
    return {nbPeriod, nbYears, chartData2, chartOptions1}
  }
}



</script>
