<template>
  <tr class="title" v-if="Object.keys(groupRow.data).length"><td :colspan="nbPeriod + 1">{{groupKey}}</td></tr>
  <template v-for="(val, key) in groupRow.data" :key="groupKey+'grpRow'+key">
    <bp-row :rowData="val" :rowKey="key"></bp-row>
  </template>
  <bp-row v-if="Object.keys(groupRow.data).length" :rowData="groupRow.total" :rowKey="'Sous Total'"></bp-row>
</template>
<script>
import BpRow from "@/components/BpRow.vue";
import gConfig from "@/parametres/global";

export default {
  components: {BpRow},
  props: {
    groupRow:Object,
    groupKey:String
  },
  setup(){
    const nbPeriod = gConfig.nbPeriod

    return {nbPeriod}
  }
}



</script>
