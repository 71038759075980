import {Company} from "@/helpers/company";
import {period} from "@/helpers/period";
import gParams from "@/parametres/global";
import numeral from "numeral";

export function globalGroup(){
    return {
        turnover: period(),
        balance: period(),
        resultRate: [],
        treasury: period("treasury"),
        debts: [],
        profitRate: [],
        profitPerPercent: [],
        profitRateInvest: [],

        head: Company("head","internal","8TECH", 0, null, true),
        internalCompany: [],
        externalCompany: [],

        createCompanies: function(id, label, type, startP, turnover, initialTreasury=0){
            const c = Company(id, type, label, startP, this)
            c.addInitialTurnover(turnover)
            c.addInitialTreasury(initialTreasury)
            if (type === "internal"){this.internalCompany.push(c)} else{this.externalCompany.push(c)}
        },

        buyToHead: function(fromType, label, p, v, margin){
            if (fromType === "external") {
                this.head.addIn(label, p, v, "outward")
            }
            else{
                this.head.addIn(label, p, v, "inward")
            }
            this.head.addOut(label, p, v-margin, "outward")
        },
        addGeneralExpenses: function(){
            this.head.addGeneralExpenses()
            for(let i of this.internalCompany){
                i.addGeneralExpenses()
            }
            for(let i of this.externalCompany){
                i.addGeneralExpenses()
            }
        },
        addLoan(id, label, vMonth, pStart, duration){
            const c = this.searchCompany(id)
            if (c === null){return false}
            c.addLoan(label, vMonth, pStart, duration)
        },
        addEmployee: function (id, label, vMonth, p){
            const c = this.searchCompany(id)
            if (c === null){return false}
            c.addEmployee(label, vMonth, p)
        },
        searchCompany: function(id){
            let res = null
            if (this.head.id === id){return this.head}
            for(let i of this.internalCompany){
                if (i.id === id){return i}
            }
            for(let i of this.externalCompany){
                if (i.id === id){return i}
            }

            return res
        },
        annualFee: function(){
            for(let p = 0; p < gParams.nbPeriod; p++){
                for(let i of this.internalCompany){
                    const fee = i.processFee("Redevance", p)
                    this.head.addIn("Redevance", p, fee, "inward")
                }
                for(let i of this.externalCompany){
                    const fee = i.processFee("Redevance", p)
                    this.head.addIn("Redevance", p, fee, "inward")
                }
            }

        },
        addPtoP: function (srcP,targetP){
            for(let p = 0; p < gParams.nbPeriod; p++){
                targetP.addValue(p,srcP.getValue(p))
            }
        },
        postProcessing: function() {
            //CA
            this.addPtoP(this.head.income.external.total,this.turnover)
            for(let i of this.internalCompany){
                this.addPtoP(i.income.external.total,this.turnover)
            }

            //Résultat
            this.addPtoP(this.head.balance,this.balance)
            for(let i of this.internalCompany){
                this.addPtoP(i.balance,this.balance)
            }

            //taux de resultat sur CA
            this.resultRate = new Array(gParams.nbPeriod/12).fill(0)
            for(let p = 0; p < gParams.nbPeriod/12; p++) {
                this.resultRate[p] = numeral(this.balance.getDataYear()[p] / this.turnover.getDataYear()[p] * 100).format('0.00')+ " %"
            }


            //Treso
            this.addPtoP(this.head.treasury,this.treasury)
            for(let i of this.internalCompany){
                this.addPtoP(i.treasury,this.treasury)
            }

            // Debts
            this.debts = new Array(gParams.nbPeriod/12).fill(0)
            for(let p = 0; p < gParams.nbPeriod/12; p++) {
                this.debts[p]+=this.head.outcome.debt.getLoanCumulGroupDataYear(p)
                for (let i of this.internalCompany) {
                    this.debts[p]+=i.outcome.debt.getLoanCumulGroupDataYear(p)
                }
            }

            // Valo
            this.profitRate = new Array(gParams.nbPeriod/12).fill(0)
            for(let p = 0; p < gParams.nbPeriod/12; p++) {
                this.profitRate[p]=this.balance.getDataYear()[p]*6+this.treasury.getDataYear()[p]-this.debts[p]
            }

            //ProfitPerPercent
            this.profitPerPercent = new Array(gParams.nbPeriod/12).fill('')
            for(let p = 0; p < gParams.nbPeriod/12; p++) {
                this.profitPerPercent[p]=numeral(this.profitRate[p]*0.01).format('0,00 $')
            }

            //profitRateInvest
            this.profitRateInvest = new Array(gParams.nbPeriod/12).fill('')
            for(let p = 0; p < gParams.nbPeriod/12; p++) {
                this.profitRateInvest[p]=numeral(this.profitRate[p]/16000-100).format('+0')+ " %"
            }
        },

    }
}
