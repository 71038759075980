export default {
    nbPeriod: 60,
    augmentationAnnuelle: 0.08,
    incomeRepartition:{
        "Prestations de services": {
            "rate":0.2391,
            "totalMarginRate": null,
            "marginRate": 1
        },
        "Materiel": {
            "rate":0.21,
            "totalMarginRate": 0.2,
            "marginRate": 0.10
        },
        "M365": {
            "rate":0.1359,
            "totalMarginRate": 0.16,
            "marginRate": 0.13
        },
        "Contrats Assist/Maint": {
            "rate":0.1284,
            "totalMarginRate": null,
            "marginRate": 1
        },
        "Sauvegarde": {
            "rate":0.1113,
            "totalMarginRate": 0.6,
            "marginRate": 0.25
        },
        "Telecom": {
            "rate":0.073,
            "totalMarginRate": 0.7,
            "marginRate": 0.3
        },
        "Datacenter": {
            "rate":0.0578,
            "totalMarginRate": 0.5,
            "marginRate": 0.25
        },
        "Antivirus": {
            "rate":0.0445,
            "totalMarginRate": 0.5,
            "marginRate": 0.25
        }
    },
}
