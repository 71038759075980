import { createApp } from 'vue'
import PrimeVue from 'primevue/config';
import VueNumerals from 'vue-numerals';
import App from './App.vue'

import 'primevue/resources/themes/mira/theme.css'
import 'primeflex/primeflex.css'
import numeral from "numeral";

numeral.register('locale', 'fr', {
    delimiters: {
        thousands: ' ',
        decimal: ','
    },
    abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't'
    },
    ordinal : function (number) {
        return number === 1 ? 'er' : 'ème';
    },
    currency: {
        symbol: '€'
    }
});
numeral.locale('fr');


const app = createApp(App)
app.use(PrimeVue)
app.use(numeral)
app.use(VueNumerals, {locale: 'fr'});
app.mount('#app')
