<template>
  <Chart type="line" :data="chartData1" :options="chartOptions1"  style="height:300px; width:100%" />
</template>

<script>
import {period} from "@/helpers/period";
import Chart from "primevue/chart";

export default {
  components: {Chart},
  props:{
    items:Object
  },
  setup(props){
    let newLicenced = period()
    let cumulLicenced = period("treasury")

    for(let i of props.items){
      newLicenced.addValue(i.startP,1)
      cumulLicenced.addToEndValue(i.startP,1)
    }

    const chartData1 = {
      labels: Array.from({length: 60}, (_, i) => i + 1),
      datasets: [
        {
          type: 'line',
          label: 'Nb licenciés',
          data: cumulLicenced.data,
        },
        {
          type: 'bar',
          label: 'Nouveaux licenciés',
          data: newLicenced.data,
        }

      ]
    }

    const chartOptions1 = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
    }


    return {cumulLicenced, chartData1, chartOptions1}
  }
}



</script>
