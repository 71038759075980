<template>
  <tr>
    <td class="font-bold">{{ rowKey }}</td>
    <template v-for="val in rowData" :key="rowKey + val">
      <td v-if="format === 'standard'">{{numeralFormat(val)}}</td>
      <td v-else>{{ val }}</td>
    </template>
  </tr>
</template>
<script>
export default {
  props: {
    rowData:Array,
    rowKey:String,
    format:{
      type:String,
      default:"standard"
    }
  }
}



</script>
